/*!

 File: set-select.js
 Author: Ember
 Version: 1.0.6 - [CC-329] fire custom event for autosave
 JS Dependencies:
    jQuery,
    chosen.jquery.js    https://harvesthq.github.io/chosen/,
    chosen.order.jquery.min.js  https://github.com/tristanjahier/chosen-order
    data-attr-check.js (check for existance of data attribute)
    form-pending.js (form ‘thinking’ functions)

 CSS Dependencies:
    _custom-chosen.scss

 Description:
    Select drop down UI for multiple and single selects includes re-ordering and 'add new option' AJAX functionality

*/

// ============================================== Select dropdown //

var setSelect = {
    init: function ($target) {

        var me = setSelect;

        // Work out if size classes are needed to be applied
        if($target.hasClass('input-sm')){
            $target.parent().addClass('chosen-sm');
        }
        if($target.hasClass('input-xs')){
            $target.parent().addClass('chosen-xs');
        }


        // Work out if an option is already selected
        var setSelectedIndex = 0;
        var thisSelectedIndex = $target.prop('selectedIndex');

        if (thisSelectedIndex > 0) {
            // offset selected index
            setSelectedIndex = thisSelectedIndex+1;
        }


        // Chosen needs a placeholder data attr and an empty first option
        var firstOption = $target.find('option:eq(0)');

        if (firstOption.attr('value') == null || firstOption.attr('value') == '') {
            $target.attr('data-placeholder', firstOption.text());
            firstOption.remove();
            // offset selected index back again
            if(setSelectedIndex>0){
                setSelectedIndex--;
            }
        }


        var firstOption = '<option></option>';

        if ($target.attr('multiple')) {
            firstOption = '<option disabled></option>';
        }

        $target.prepend(firstOption);

        // Select 'selected' option again (if not a multi select)
        if(!$target.attr('multiple')){
            $target.find('option:eq('+setSelectedIndex+')').prop('selected', true);
        }

        // Check for max options
        var selectMaxOptions = $target.data('max-options');
        var setMaxOptions = 0;

        if(selectMaxOptions){
            setMaxOptions = selectMaxOptions;
        };

        // Check for disable placeholder
        var selectSingleDeselect = true;
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-no-placeholder', true)) {
            selectSingleDeselect = false;
        }

        // Check for Searchwithin
        var selectSearchContains = false;
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-search-contains', true)) {
            selectSearchContains = true;
        }

        // Check for option-width
        var selectWidth = '100%';
        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-option-width', true)) {
            selectWidth = null;
        }

        // Custom event which Vanilla js scripts can hook into
        var changeEvt = new CustomEvent('customSelectChange', {
            bubbles: true,
            cancelable: true,
        });

        // ============================================== Initialise Chosen  //
        // Check that it's not already init'ed
        if(!$target.data('chosen')){
            $target.chosen({
                disable_search_threshold: 10,
                width: selectWidth,
                allow_single_deselect: selectSingleDeselect,
                max_selected_options: setMaxOptions,
                search_contains: selectSearchContains
            }).change(function () {
                // dispatch Custom event for vanilla js
                $target[0].dispatchEvent(changeEvt);
                //
                if ($target.prop('required')) {
                    if ($target.closest('form').attr('novalidate')) {
                        $(this).valid(); // triggering validator plugin if initialised
                    }
                }
            });
        }




        // ============================================== Values Container  //

        // .values-container is used to store the order of multi-select element
        var $formBlock = $target.parent();
        //var $formBlock = $target.closest('.form-group'); // this won't work within grid fields !
        var $thisValuesContainer = $formBlock.find('.values-container');


        if ($thisValuesContainer.length) {

            // On init set the order of the tags in the Chosen field according to the order of the .values-container field
            var orderList = [];
            $thisValuesContainer.children('input').each(function () {
                orderList.push($(this).val());
            });

            if (orderList.length) {
                // plugin will respond with a page breaking error if the list doesn't match what is in the select
                var allOptionsExist = true;
                for(i=0; i<orderList.length; i++){
                    if(!$target.find('option[value="' + orderList[i] + '"]').length){
                        allOptionsExist = false;
                    }
                }
                if(allOptionsExist){
                    // Trigger chosen.order.jquery plugin
                    //$target.setSelectionOrder(orderList);
                }else{
                    var selectName = $target.attr('name');
                    if (selectName == undefined){
                        selectName = $target.data('name');
                    }
                    console.log("An option defined in values-container doesn't exist in the select so it can't be ordered. Select name = "+selectName);
                }
            }

            // Every time select is changed update the values list
            $target.change(function(event, params) {
                me.setSelectOrder(params,$target,$thisValuesContainer);
            });

            // Make multi select draggable
            var $chosen = $target.siblings('.chosen-container');

            // On mousedown of choice element we don't want to display the dropdown list
            $chosen.find('.chosen-choices').bind('mousedown', function(event){
                if ($(event.target).is('span')) {
                    event.stopPropagation();
                }
            });

            // Initialize jQuery UI Sortable (if not disabled)
            /*if(!$target.prop('disabled') && !$target.prop('readonly')){
                $chosen.find('.chosen-choices').sortable({
                    //'placeholder' : 'ui-state-highlight',
                    'items'       : 'li:not(.search-field)',
                    'tolerance'   : 'pointer',
                    'containment' : 'parent',
                    'stop'        : function() {
                        me.setSelectOrder(null,$target,$thisValuesContainer);
                    }
                });
            }*/

        }

        // allows data-attribute and data-attribute="true"  otherwise returns false
        if (checkDataAttr($target, 'data-form-url', true)) {
            me.initNewOption($target);
        }

    },
    // Change order of selected options in multi select
    setSelectOrder: function(params, $target, $thisValuesContainer){
        // Get Chosen selection order via chosen/order plugin
        var selection = $target.getSelectionOrder();

        // Clear values
        $thisValuesContainer.html('');

        // Create name from select element
        var name = $target.data('name');

        // param needs to be an object
        if (!params){
            params={};
        }

        // Build list of inputs
        for (i in selection) {
            if (params['deselected'] != 'undefined' && params['deselected'] != selection[i]) { // if I'm not being removed
                var single_value = $('<input>').attr({
                    type: 'hidden',
                    //type: 'text', // use for debugging
                    name: name + '[]',
                    value: selection[i]
                });
            }
            $(single_value).appendTo($thisValuesContainer);
        }

    },
    // Create and add a new option to select using AJAX form
    initNewOption: function($target) {
        var me = setSelect;

        // Vars
        var $addButton = $target.parent().find('.js-select-add-option-button').first();
        var $resultsOuter = $target.parent().parent().find('.js-select-add-option-form');
        var $resultsContainerInner = $resultsOuter.find('.js-select-add-option-results-inner');
        var $chosen = $target.siblings('.chosen-container');

        // Class for no results hover
        $chosen.addClass('chosen-with-new-option');

        // Add entity title to form
        if (checkDataAttr($target, 'data-entity-type-readable', true)) {
            $resultsOuter.find('.js-entity-readable').html($target.data('entity-type-readable'));
        }


        // Chosen results message click
        $chosen.on('click', '.no-results', function(e){
            e.preventDefault();

            // Can't trigger close a chosen while mouse is over it
            $chosen.find('.chosen-drop').hide();
            $chosen.trigger('mouseleave');
            setTimeout(function(){
                $target.trigger('chosen:close');
                setTimeout(function(){
                    $chosen.find('.chosen-drop').removeAttr('style');
                }, 100);
            }, 50);

            me.showAjaxForm($target);
        });

        // + button click
        $addButton.on('click', $(this), function(e){
            e.preventDefault();
            me.showAjaxForm($target);
        });

        // Cancel button
        $resultsOuter.find('.js-select-add-option-form-cancel').on('click', $(this), function(e){
            e.preventDefault();
            //
            //$resultsOuter.hide();
            me.closeAjaxForm($target);

        });

        // Edit options
        if (checkDataAttr($target, 'data-select-edit', true)) {
            me.initEditOption($target);
        }

    },
    // Initialise the Edit Option Functionality
    initEditOption: function($target){
        var me = setSelect;

        // Create container for links
        var $editContainer = $('<div>', {
            'class': 'select-edit-option-buttons js-select-edit-option-buttons'
        });

        $editContainer.on('click', 'a', function(e){
            e.preventDefault();
            // pass id to showAjaxForm
            me.showAjaxForm($target, $(this).data('select-edit-id'));
        });

        $target.parent().append($editContainer);

        // Add change event to select
        $target.on('change', function() {
            me.processEditLinks($target);
        });

        // Generate links based on current selected options
        me.processEditLinks($target);

    },
    // Traverse through select field and generate edit links for each selected option
    processEditLinks: function($target){
        var me = setSelect;
        // Only if the edit links has already been initialised
        var $editButtonsContainer = $target.parent().find('.js-select-edit-option-buttons');

        if($editButtonsContainer.length){

            //clear current HTML
            $target.parent().find('.js-select-edit-option-buttons').html('');
            // Generate links based on current selected options
            $target.find('option:selected').each(function(i) {
                if($(this).val()!=''){
                    me.addEditLink($target, $(this).text(), $(this).val());
                }
            });

        }

    },
    // Add a single edit link to dom
    addEditLink: function($target, optionVal, optionId){
        var $link = $('<a>', {
            'class': 'js-select-edit-option-button',
            'href': '#',
            'text': 'Edit '+optionVal
        });
        $link.data('select-edit-id', optionId);

        $target.parent().find('.js-select-edit-option-buttons').append($link);
    },
    // Reveal AJAX form ready for loading
    showAjaxForm: function($target, formId) {
        var me = setSelect;

        // vars
        var $resultsOuter = $target.parent().parent().find('.js-select-add-option-form');
        var $resultsContainer = $resultsOuter.find('.js-select-add-option-results');
        var $resultsContainerInner = $resultsOuter.find('.js-select-add-option-results-inner');

        // Reset HTML
        $resultsContainerInner.html('');

        // Set title Add/Edit
        if(formId){
            $resultsOuter.find('.js-entity-context').text('Edit');
        }else{
            $resultsOuter.find('.js-entity-context').text('Add new');
        }

        // scroll to field
        $('html, body').animate({
            scrollTop: $target.parent().offset().top - 100 // 100 = offset for sticky header
        }, 300);

        // Show outer
        $resultsOuter.css({'opacity':0}).slideDown(200, function(){
            $resultsOuter.fadeTo(200, 1, function(){
                me.loadAjaxForm($target, formId);
            });
        });

    },
    // Load form fields for new select option
    loadAjaxForm: function($target, formId) {
        var me = setSelect;

        // vars
        var params;
        var $resultsOuter = $target.parent().parent().find('.js-select-add-option-form');
        var $resultsContainer = $resultsOuter.find('.js-select-add-option-results');
        var $resultsContainerInner = $resultsOuter.find('.js-select-add-option-results-inner');
        var $spinner = $resultsContainer.find('.ajax-spinner');
        var $saveButton = $resultsOuter.find('.js-select-add-option-form-save');


        // Thinking...
        $spinner.show().fadeTo(0.01, 1);
        $saveButton.addClass('disabled').prop('disabled', true);

        // Add id to form
        var urlId = '';
        if(formId){
            urlId = '/'+formId;
        }
        // Get form URL
        var formUrl = $target.data('form-url')+urlId;

        // Clear any previous JSON Calls
        if (typeof xhr_array['select_editor'] !== 'undefined') {
            xhr_array['select_editor'].abort();
        }

        // Get form fields with AJAX call
        xhr_array['dt_editor'] = $.getJSON(formUrl, params, function (responseJson) {
            // Keep height
            $resultsContainer.css({'min-height':$resultsContainer.height()});

            // Fade out spinner
            $spinner.fadeTo(200, 0, function(){

                // stop thinking
                $saveButton.removeClass('disabled').prop('disabled', false);

                // Get the response HTML string and convert it to nodes,
                // that way it can be manipulated before being inserted
                var responseHtml = jQuery.parseHTML(responseJson.html);

                // Set HTML and hide
                $resultsContainerInner.slideDown(0.01).fadeTo(0.01, 1).html(responseHtml);

                // Init any JS fields in form
                initFields($resultsContainerInner, function(){
                    // Callback function to ensure fields are intialised before...
                    me.setFields($target, $resultsContainerInner);
                });

                // Init the save button
                me.initSaveEditForm($target, $resultsOuter );

                // Disable parent save
                // If i'm in a modal
                // @ TODO EGS-61 add a class to all modal save buttons and use this instead of .js-dt-modal-save
                $target.closest('.modal-body').parent().find('.js-dt-modal-save').addClass('disabled').prop('disabled', true);
                // Disable main page save button (so we don't submit contents of edit form along with main page form)
                disablePageSave.disable();

                // show
                $resultsContainerInner.hide().slideDown(300, function(){
                    // Focus first field
                    $resultsContainerInner.find(':input:visible:enabled:first').focus()
                });

            });

        });
    },
    // Save event for new select option form
    initSaveEditForm: function($target, $resultsOuter ){
        var me = setSelect;

        var $saveButton = $resultsOuter.find('.js-select-add-option-form-save');
        var $resultsContainerInner = $resultsOuter.find('.js-select-add-option-results-inner');

        // Set the click event
        $saveButton.unbind('click').click(function(e){
            e.preventDefault();
            // Post data URL
            var saveFormUrl;
            // If data attr exists and isn't empty
            if (checkDataAttr($target, 'data-update-url')) {
                saveFormUrl = $target.data('update-url');
            }

            // We can't serailize the form in case there is a nested form so need to serialize all individual fields
            var serialisedForm = $resultsContainerInner.find('input[name], textarea[name], select[name]').serializeArray();

            if(saveFormUrl){
                // Thinking...
                pendingButton.show($saveButton);
                pendingForm.disable($resultsContainerInner);

                // Default AJAX options (serialised data)
                var thisData = $.param(serialisedForm);
                var thisProcessData = true;
                var thisContentType = 'application/x-www-form-urlencoded; charset=UTF-8';

                // AJAX request
                $.ajax({
                    url: saveFormUrl,
                    data: thisData,
                    processData: thisProcessData,
                    contentType: thisContentType,
                    type: 'POST',
                    success: function(response) {
                        var responseJson = jQuery.parseJSON(response);

                        // Stop thinking
                        pendingForm.enable($resultsContainerInner);
                        pendingButton.hide($saveButton, function(){

                            // Returned Success
                            if(responseJson.status=='success'){

                                var $existingOption = $target.find('option[value="'+ responseJson.entity.id +'"]');

                                if($existingOption.length){
                                    // If option already exists update it
                                    $existingOption.text(responseJson.entity.display_title);
                                }else{
                                    // Append new option to select
                                    $target.append($('<option>', {
                                        value: responseJson.entity.id,
                                        text: responseJson.entity.display_title,
                                        selected: true
                                    }));
                                }

                                // Update Chosen
                                $target.trigger('chosen:updated');
                                // also trigger browser change event to set .values-container
                                $target.change();

                                // Re generate edit links
                                me.processEditLinks($target);

                                // Close form
                                me.closeAjaxForm($target);

                            }

                        });
                    }
                });

            }
        });

    },
    // Close the AJAX form
    closeAjaxForm: function($target){
        var $resultsOuter = $target.parent().parent().find('.js-select-add-option-form');
        var $resultsContainerInner = $resultsOuter.find('.js-select-add-option-results-inner');

        // scroll back to original field
        $('html, body').animate({
            scrollTop: $target.parent().offset().top - 100 // 100 = offset for sticky header
        }, 300);

        // Animate close form
        $resultsContainerInner.slideUp(300, function(){
            $resultsOuter.fadeTo(300, 0, function(){

                $resultsOuter.slideUp(300, function(){

                    // Clear old fields
                    $resultsOuter.find('.js-select-add-option-results-inner').html('');

                    // Enable modal save button
                    $target.closest('.modal-body').parent().find('.js-dt-modal-save').removeClass('disabled').prop('disabled', false);

                    // Enable page save button
                    disablePageSave.enable();

                });
            });
        });
    },
    // Set the content / status of fields in modal form based on data attribute
    setFields: function($target, $resultsContainerInner){


        // If data attr exists and isn't empty
        if (checkDataAttr($target, 'data-set-field')) {
            // Get field array from data
            var setFields = $target.data('set-field');

            // Array for storing new hidden fields (if required)
            var hiddenFieldArray = [];

            // function to generate a hidden field - for single selects and items without an already existing field
            var addNewField = function(i){
                var newField = $('<input>').attr({
                    type: 'hidden',
                    //type: 'text', // switch to 'text' for testing
                    name: setFields[i].field_name,
                    value: setFields[i].value
                });
                hiddenFieldArray.push(newField);
            }

            // Loop through object array
            for(var i=0; i<setFields.length; i++){

                // Get field
                var $setFieldsField = $resultsContainerInner.find('.form-control[name="' + setFields[i].field_name + '"]');
                // Multiselects dont have name attributes so try looking for a 'data-name'
                if(!$setFieldsField.length){
                    $setFieldsField = $resultsContainerInner.find('.form-control[data-name="' + setFields[i].field_name + '"]');
                }

                // If field already exists in the form
                if($setFieldsField.length){

                    // Disable field
                    $setFieldsField.prop('readonly', true);

                    // Update val - (we're not checking if that select option exists)
                    $setFieldsField.val(setFields[i].value);

                    // If I'm a single select need to create a blank field as you can't have 'readonly' selects (and we want the data posted on submit)
                    // (Multi selects already have hidden fields)
                    if($setFieldsField.prop('type') == 'select-one') {
                        addNewField(i);
                    }

                    // Trigger 'Chosen' update if needed
                    if($setFieldsField.hasClass('js-select')){
                        $setFieldsField.prop('disabled', true).trigger('chosen:updated');
                        $setFieldsField.change(); // also trigger browser change event to set .values-container
                    }

                }else{
                    // If doesn't already exist create a hidden field
                    addNewField(i);
                }

            }

            // If we have created hidden fields add them to form
            if(hiddenFieldArray.length > 0){
                $resultsContainerInner.append(hiddenFieldArray);
            }

        }

    },

    destroy: function () {
    }
};
